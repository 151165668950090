@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

.font-sans {font-family: "Public Sans", sans-serif;}
.font-serif { font-family: "Public Sans", sans-serif; }

body { @apply font-sans font-normal text-neutral-800 md:text-sm lg:text-lg; }
body a { @apply no-underline text-white; }
.gray-shadow { box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2); }
.text-shad { text-shadow: 0px 10px 1px 15px rgba(0, 0, 0, 0.8); }
nav .navbar-menu a { @apply font-sans font-medium; }
//nav .navbar-menu .submenu a { @apply flex items-center font-medium text-gray-700 hover:text-white hover:bg-cyan-800 py-2 px-3; }
body p { @apply mb-4; }

h1, h2, h3, h4, h5, h6 { font-family: "Public Sans", sans-serif; line-height: 1.2em !important; }
h1 { @apply text-4xl md:text-5xl lg:text-6xl drop-shadow-md mb-4; }
h1.heading { @apply text-5xl md:text-7xl lg:text-8xl mb-4;}
h2 { @apply text-3xl md:text-4xl lg:text-5xl mb-4; }
h3 { @apply text-2xl md:text-3xl lg:text-4xl mb-4; }
h4 { @apply text-xl md:text-2xl lg:text-3xl mb-4; }
h5 { @apply text-lg md:text-xl lg:text-2xl mb-4; }
h6 { @apply text-base md:text-lg lg:text-xl mb-4; }

.slider-sec { @apply bg-auto bg-no-repeat bg-bottom relative overflow-hidden; }
.slider-sec:before { @apply bg-fixed absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden; }

.title-heading { @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full; }
.title-heading:before { @apply bg-fixed absolute /*bg-gradient-to-r from-neutral-900 to-transparent*/ bg-neutral-900 bg-opacity-10 bottom-0 left-0 right-0 top-0 w-full overflow-hidden transform; }

.parallex { @apply bg-fixed bg-cover bg-no-repeat bg-center relative overflow-hidden w-full; }
.parallex:before { @apply absolute bg-sky-800 bg-opacity-80 bottom-0 left-0 right-0 top-0 w-full overflow-hidden; }

.parallex2 { @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full; }
.parallex2:before { @apply absolute bg-gradient-to-b from-transparent via-transparent to-sky-900 bottom-0 left-0 right-0 top-0 w-full overflow-hidden; }

.col-effect { @apply bg-cover bg-no-repeat bg-center relative overflow-hidden p-8 pt-56 md:pt-64 rounded-tr-3xl rounded-tl-[5rem] rounded-br-[5rem] rounded-bl-3xl; }
.col-effect:before { @apply bg-gradient-to-b from-transparent to-neutral-800 bg-opacity-5 absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden; }
.col-justify p { @apply text-justify; }
.whitebox { @apply bg-white rounded-2xl gray-shadow mb-8 p-6 md:p-8;
  .title { @apply lg:text-base font-semibold text-black mb-5; }
}

.whitebox-xl { @apply bg-gradient-to-br from-sky-600 to-orange-400 rounded-[15px] p-1 text-sm lg:text-base ;
.inside-box { @apply bg-gray-200 items-stretch rounded-[13px] p-4 md:p-6 h-full; }
}


// .hex.generic-2 { @apply bg-gray-400 relative overflow-visible mx-auto;
//   ::before, ::after { @apply contents absolute top-0 border-t-8 border-b-8 border-transparent ; }
// }
// .hex.generic-2:before { @apply right-full border-r-8 border-sky-400; }
// .hex.generic-2:after { @apply left-full border-l-8 border-sky-400; }

.hexagon { @apply bg-white bg-opacity-60 relative w-40 h-40; }
.hexagon:before, .double:after { position: absolute; content: ''; }
.hexagon:before { top: 8px; left: 8px; height: calc(100% - 16px); width: calc(100% - 16px); background: rgb(249, 115, 22); z-index: -1; }
.hexagon, .hexagon:before, .double:after { clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); }

.contact-box { @apply bg-orange-500 p-3 pb-0;
.box-inside { @apply border border-gray-200 h-full p-4 md:p-6; }
}
.contact3 { position: relative; }
.contact3:before { content: ""; position: absolute; left: 10px; top: 10px; width: calc(100% - 20px); height: calc(100% - 10px); }
.contact3:after { content: ""; position: absolute; left: 0px; right: 0px; margin: auto; bottom: -30px; width: 60px; height: 60px; transform: rotate(45deg); }

.btn-theme1 { @apply font-medium text-white border-b border-white px-4 py-2 uppercase; }
.btn-blue { @apply rounded font-medium text-white bg-blue-900 hover:bg-cyan-600 transition-all text-xs py-2 px-6; }
.btn-red-lg { @apply font-medium text-white bg-red-600 hover:bg-red-800 transition-all uppercase py-4 px-4 lg:py-6 lg:px-8; }
.btn-red-sm { @apply font-medium text-white bg-red-600 hover:bg-red-800 transition-all text-sm py-2 px-4; }
.btn-whoutline { @apply font-medium text-white border-2 border-white hover:bg-white w-full block transition-all text-sm uppercase text-center py-2 px-6;
}

.szh-accordion__item { @apply rounded-md border border-gray-300 mb-3 p-4; }
.szh-accordion__item h3 { @apply font-bold text-sm lg:text-base text-black; }
.szh-accordion__item-content { @apply pt-4; }

/* ================== PRODUCT CSS HERE ================== */
.products {
  .product-items {
    @apply bg-zinc-950 border border-zinc-800;
    .product-details {
      @apply p-4 md:p-6;
      h6 {
        @apply text-white font-medium;
      }
    }
  }
}

footer {
  @apply text-gray-800;
  h6.title { @apply font-bold text-white mb-8 uppercase; }

  ul.bottommenu { @apply flex items-center justify-start font-semibold my-6;
    li { @apply mx-4;
      a { @apply border-b border-white px-4 py-2 uppercase; }
    }
  }
}
